import IconToImage from './IconToImage';
import { GiNinjaMask } from 'react-icons/gi';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const ninjaAppear = keyframes`
  0% {
    opacity: 0;
    transform: scale(0) rotate(0deg);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.2) rotate(180deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(360deg);
  }
`;

const Container = styled.div`
  background-color: #f8f9fa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
  color: #ffffff;
  padding: 2rem 1rem;
  text-align: center;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at center, rgba(255, 64, 129, 0.1) 0%, transparent 70%);
    pointer-events: none;
  }
`;

const NinjaIcon = styled(GiNinjaMask)`
  font-size: 3rem;
  color: #ff4081;
  animation: ${ninjaAppear} 1.5s ease-out;
  filter: drop-shadow(0 0 8px rgba(255, 64, 129, 0.5));
  
  &:hover {
    transform: rotate(360deg);
    transition: transform 0.8s ease-in-out;
  }
`;

const Title = styled.h1`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-size: 3rem;
  font-family: 'Ninjutsu', 'Segoe UI', system-ui, -apple-system, sans-serif;
  font-weight: 700;
  text-shadow: 0 0 10px rgba(255, 64, 129, 0.5),
               0 0 20px rgba(255, 64, 129, 0.3);
  letter-spacing: 0.125rem;
`;

const Subtitle = styled.p`
  margin: 1rem 0 0;
  font-size: 1.25rem;
  color: #e0e0e0;
  font-weight: 300;
  letter-spacing: 0.05rem;
  opacity: 0.9;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
`;

const Main = styled.main`
  flex: 1;
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
`;

function App() {
  return (
    <Container>
      <Header>
        <Title>
          <NinjaIcon />
          Icon Ninja
        </Title>
        <Subtitle>
          Swift and Silent Icon Conversion
        </Subtitle>
      </Header>
      <Main>
        <IconToImage />
      </Main>
    </Container>
  );
}

export default App;
import React, { useRef, useState, useEffect } from 'react';
import * as FaIcons from 'react-icons/fa';  // Import all FA icons
import * as Fa6Icons from 'react-icons/fa6';  // Font Awesome 6
import * as AiIcons from 'react-icons/ai';  // Ant Design Icons
import * as BiIcons from 'react-icons/bi';  // Boxicons
import * as BsIcons from 'react-icons/bs';  // Bootstrap Icons
import * as IoIcons from 'react-icons/io5'; // Ionicons 5
import * as Io4Icons from 'react-icons/io';   // Ionicons 4
import * as MdIcons from 'react-icons/md';  // Material Design icons
import * as RiIcons from 'react-icons/ri';  // Remix Icons
import * as TbIcons from 'react-icons/tb';  // Tabler Icons
import * as LuIcons from 'react-icons/lu';  // Lucide Icons
import * as HiIcons from 'react-icons/hi2';  // Heroicons 2
import * as Hi1Icons from 'react-icons/hi';   // Heroicons 1
import * as SiIcons from 'react-icons/si';  // Simple Icons
import * as DiIcons from 'react-icons/di';  // Devicons
import * as GiIcons from 'react-icons/gi';  // Game Icons
import * as VscIcons from 'react-icons/vsc';  // VS Code Icons
import * as ImIcons from 'react-icons/im';  // IcoMoon Free
import * as CgIcons from 'react-icons/cg';    // css.gg
import * as TiIcons from 'react-icons/ti';    // Typicons
import * as GoIcons from 'react-icons/go';    // Github Octicons
import * as WiIcons from 'react-icons/wi';    // Weather Icons
import * as FiIcons from 'react-icons/fi';    // Feather Icons
import * as CiIcons from 'react-icons/ci';    // Circum Icons
import * as GrIcons from 'react-icons/gr';    // Grommet Icons
import * as SlIcons from 'react-icons/sl';    // Simple Line Icons
import * as PiIcons from 'react-icons/pi';    // Phosphor Icons
import * as RxIcons from 'react-icons/rx';    // Radix Icons
import * as LiaIcons from 'react-icons/lia';  // Icons8 Line Awesome
import * as ThIcons from 'react-icons/tfi';   // Themify Icons
import html2canvas from 'html2canvas';
import styled from 'styled-components';

const SearchContainer = styled.div`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const SearchRow = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  &:first-of-type {
    margin-bottom: 0.5rem;
  }
`;

const SearchLabel = styled.label`
  font-size: 0.875rem;
  color: #666;
  margin-bottom: 0.25rem;
  display: block;
`;

const SearchGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchInput = styled.input`
  padding: 0.75rem 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  width: 300px;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: #ff4081;
    box-shadow: 0 0 0 3px rgba(255, 64, 129, 0.1);
  }
`;

const Select = styled.select`
  padding: 0.75rem 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  background: white;
  cursor: pointer;
  min-width: 200px;

  &:focus {
    outline: none;
    border-color: #ff4081;
    box-shadow: 0 0 0 3px rgba(255, 64, 129, 0.1);
  }
`;

const ColorInput = styled.input`
  padding: 0.5rem;
  width: 100px;
  height: 40px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;

  &::-webkit-color-swatch {
    border: none;
    border-radius: 4px;
  }
`;

const FormatToggle = styled.div`
  display: flex;
  gap: 0.5rem;
  background: white;
  padding: 0.25rem;
  border-radius: 8px;
  border: 2px solid #e0e0e0;
`;

const FormatButton = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  background: ${props => props.active ? '#ff4081' : 'transparent'};
  color: ${props => props.active ? 'white' : '#666'};
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.875rem;
  font-weight: 500;

  &:hover {
    background: ${props => props.active ? '#f50057' : '#f5f5f5'};
  }
`;

const IconGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
`;

const IconActions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  display: flex;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.2s;
`;

const ActionButton = styled.button`
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #e0e0e0;
  color: #666;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  backdrop-filter: blur(4px);

  &:hover {
    background: white;
    color: #ff4081;
    border-color: #ff4081;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const IconCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  transition: all 0.2s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    ${IconActions} {
      opacity: 1;
    }
  }
`;

const IconPreview = styled.div`
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  cursor: pointer;
  position: relative;

  &:hover {
    &::after {
      content: 'Click to copy';
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 0.75rem;
      color: #666;
      white-space: nowrap;
    }
  }

  ${props => props.copied && `
    animation: pulse 0.3s ease-in-out;
  `}

  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }
`;

const IconName = styled.div`
  font-size: 0.875rem;
  color: #666;
  text-align: center;
  word-break: break-word;
`;

const Pagination = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 2rem;
  align-items: center;
`;

const PageButton = styled.button`
  padding: 0.5rem 1rem;
  border: 2px solid ${props => props.active ? '#ff4081' : '#e0e0e0'};
  background: ${props => props.active ? '#ff4081' : 'white'};
  color: ${props => props.active ? 'white' : '#666'};
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    border-color: #ff4081;
    color: ${props => props.active ? 'white' : '#ff4081'};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const CopyFeedback = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 0.875rem;
  z-index: 1000;
  animation: fadeInOut 1.5s ease-in-out;
  pointer-events: none;

  @keyframes fadeInOut {
    0% { opacity: 0; transform: translate(-50%, 20px); }
    20% { opacity: 1; transform: translate(-50%, 0); }
    80% { opacity: 1; transform: translate(-50%, 0); }
    100% { opacity: 0; transform: translate(-50%, -20px); }
  }
`;

const IconToImage = () => {
  const iconRefs = useRef([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLibrary, setSelectedLibrary] = useState('all');
  const [globalColor, setGlobalColor] = useState('#000000');
  const [format, setFormat] = useState('PNG');
  const [currentPage, setCurrentPage] = useState(1);
  const [copiedIcon, setCopiedIcon] = useState(null);
  const [showCopyFeedback, setShowCopyFeedback] = useState(false);
  const ICONS_PER_PAGE = 20;

  // Create an array of all icons with their libraries
  const allIcons = [
    ...Object.entries(FaIcons).map(([name, component]) => ({ 
      name: name.replace('Fa', ''), 
      component, 
      library: 'Font Awesome' 
    })),
    ...Object.entries(Fa6Icons).map(([name, component]) => ({ 
      name: name.replace('Fa6', ''), 
      component, 
      library: 'Font Awesome 6' 
    })),
    ...Object.entries(AiIcons).map(([name, component]) => ({ 
      name: name.replace('Ai', ''), 
      component, 
      library: 'Ant Design' 
    })),
    ...Object.entries(BiIcons).map(([name, component]) => ({ 
      name: name.replace('Bi', ''), 
      component, 
      library: 'Boxicons' 
    })),
    ...Object.entries(BsIcons).map(([name, component]) => ({ 
      name: name.replace('Bs', ''), 
      component, 
      library: 'Bootstrap' 
    })),
    ...Object.entries(IoIcons).map(([name, component]) => ({ 
      name: name.replace('Io', ''), 
      component, 
      library: 'Ionicons' 
    })),
    ...Object.entries(MdIcons).map(([name, component]) => ({ 
      name: name.replace('Md', ''), 
      component, 
      library: 'Material' 
    })),
    ...Object.entries(RiIcons).map(([name, component]) => ({ 
      name: name.replace('Ri', ''), 
      component, 
      library: 'Remix' 
    })),
    ...Object.entries(TbIcons).map(([name, component]) => ({ 
      name: name.replace('Tb', ''), 
      component, 
      library: 'Tabler' 
    })),
    ...Object.entries(LuIcons).map(([name, component]) => ({ 
      name: name.replace('Lu', ''), 
      component, 
      library: 'Lucide' 
    })),
    ...Object.entries(HiIcons).map(([name, component]) => ({ 
      name: name.replace('Hi2', ''), 
      component, 
      library: 'Heroicons' 
    })),
    ...Object.entries(SiIcons).map(([name, component]) => ({ 
      name: name.replace('Si', ''), 
      component, 
      library: 'Simple Icons' 
    })),
    ...Object.entries(DiIcons).map(([name, component]) => ({ 
      name: name.replace('Di', ''), 
      component, 
      library: 'Devicons' 
    })),
    ...Object.entries(GiIcons).map(([name, component]) => ({ 
      name: name.replace('Gi', ''), 
      component, 
      library: 'Game Icons' 
    })),
    ...Object.entries(VscIcons).map(([name, component]) => ({ 
      name: name.replace('Vsc', ''), 
      component, 
      library: 'VS Code' 
    })),
    ...Object.entries(ImIcons).map(([name, component]) => ({ 
      name: name.replace('Im', ''), 
      component, 
      library: 'IcoMoon' 
    })),
    ...Object.entries(Io4Icons).map(([name, component]) => ({ 
      name: name.replace('Io', ''), 
      component, 
      library: 'Ionicons 4' 
    })),
    ...Object.entries(Hi1Icons).map(([name, component]) => ({ 
      name: name.replace('Hi', ''), 
      component, 
      library: 'Heroicons 1' 
    })),
    ...Object.entries(CgIcons).map(([name, component]) => ({ 
      name: name.replace('Cg', ''), 
      component, 
      library: 'css.gg' 
    })),
    ...Object.entries(TiIcons).map(([name, component]) => ({ 
      name: name.replace('Ti', ''), 
      component, 
      library: 'Typicons' 
    })),
    ...Object.entries(GoIcons).map(([name, component]) => ({ 
      name: name.replace('Go', ''), 
      component, 
      library: 'Github Octicons' 
    })),
    ...Object.entries(WiIcons).map(([name, component]) => ({ 
      name: name.replace('Wi', ''), 
      component, 
      library: 'Weather Icons' 
    })),
    ...Object.entries(FiIcons).map(([name, component]) => ({ 
      name: name.replace('Fi', ''), 
      component, 
      library: 'Feather' 
    })),
    ...Object.entries(CiIcons).map(([name, component]) => ({ 
      name: name.replace('Ci', ''), 
      component, 
      library: 'Circum Icons' 
    })),
    ...Object.entries(GrIcons).map(([name, component]) => ({ 
      name: name.replace('Gr', ''), 
      component, 
      library: 'Grommet' 
    })),
    ...Object.entries(SlIcons).map(([name, component]) => ({ 
      name: name.replace('Sl', ''), 
      component, 
      library: 'Simple Line' 
    })),
    ...Object.entries(PiIcons).map(([name, component]) => ({ 
      name: name.replace('Pi', ''), 
      component, 
      library: 'Phosphor' 
    })),
    ...Object.entries(RxIcons).map(([name, component]) => ({ 
      name: name.replace('Rx', ''), 
      component, 
      library: 'Radix' 
    })),
    ...Object.entries(LiaIcons).map(([name, component]) => ({ 
      name: name.replace('Lia', ''), 
      component, 
      library: 'Line Awesome' 
    })),
    ...Object.entries(ThIcons).map(([name, component]) => ({ 
      name: name.replace('Tfi', ''), 
      component, 
      library: 'Themify' 
    }))
  ].filter(icon => typeof icon.component === 'function');

  const [iconColors, setIconColors] = useState(
    Object.fromEntries(allIcons.map(icon => [icon.name, globalColor]))
  );

  const handleGlobalColorChange = (color) => {
    setGlobalColor(color);
    // Update all icon colors
    setIconColors(
      Object.fromEntries(allIcons.map(icon => [icon.name, color]))
    );
  };

  const handleColorChange = (name, color) => {
    setIconColors(prev => ({
      ...prev,
      [name]: color
    }));
  };

  const downloadIcon = async (index, icon, format) => {
    if (iconRefs.current[index]) {
      try {
        const iconElement = iconRefs.current[index];
        const canvas = await html2canvas(iconElement, {
          backgroundColor: null,
          scale: 4,
          logging: false,
          width: iconElement.offsetWidth,
          height: iconElement.offsetHeight
        });
        
        const tempCanvas = document.createElement('canvas');
        tempCanvas.width = canvas.width;
        tempCanvas.height = canvas.height;
        const ctx = tempCanvas.getContext('2d');
        ctx.drawImage(canvas, 0, 0);
        
        let imgURL;
        let extension;
        
        switch(format) {
          case 'jpg':
            imgURL = tempCanvas.toDataURL('image/jpeg', 0.9);
            extension = 'jpg';
            break;
          case 'webp':
            imgURL = tempCanvas.toDataURL('image/webp', 0.9);
            extension = 'webp';
            break;
          case 'svg':
            // Note: This is a basic SVG conversion
            const svgString = `
              <svg width="${canvas.width}" height="${canvas.height}" xmlns="http://www.w3.org/2000/svg">
                <image href="${tempCanvas.toDataURL()}" width="100%" height="100%"/>
              </svg>
            `;
            imgURL = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svgString);
            extension = 'svg';
            break;
          default:
            imgURL = tempCanvas.toDataURL('image/png');
            extension = 'png';
        }

        const link = document.createElement('a');
        link.href = imgURL;
        link.download = `${icon.library}-${icon.name}-icon.${extension}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error(`Error converting icon:`, error);
      }
    }
  };

  const copyIcon = async (index) => {
    try {
      const iconRef = iconRefs.current[index];
      const canvas = await html2canvas(iconRef, {
        backgroundColor: null,
      });
      
      canvas.toBlob(async (blob) => {
        await navigator.clipboard.write([
          new ClipboardItem({
            [blob.type]: blob
          })
        ]);
        
        setCopiedIcon(index);
        setShowCopyFeedback(true);
        
        setTimeout(() => {
          setCopiedIcon(null);
          setShowCopyFeedback(false);
        }, 1500);
      });
    } catch (error) {
      console.error('Failed to copy icon:', error);
    }
  };

  const filteredIcons = allIcons.filter(icon => {
    const matchesSearch = icon.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesLibrary = selectedLibrary === 'all' || icon.library === selectedLibrary;
    return matchesSearch && matchesLibrary;
  });

  const totalPages = Math.ceil(filteredIcons.length / ICONS_PER_PAGE);
  const paginatedIcons = filteredIcons.slice(
    (currentPage - 1) * ICONS_PER_PAGE,
    currentPage * ICONS_PER_PAGE
  );

  // Reset to first page when filters change
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, selectedLibrary]);

  const libraries = ['all', ...new Set(allIcons.map(icon => icon.library))];

  return (
    <div>
      <SearchContainer>
        <SearchRow>
          <SearchGroup>
            <SearchLabel>Search Icons</SearchLabel>
            <SearchInput
              type="text"
              placeholder="Type to search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </SearchGroup>
          <SearchGroup>
            <SearchLabel>Library</SearchLabel>
            <Select
              value={selectedLibrary}
              onChange={(e) => setSelectedLibrary(e.target.value)}
            >
              <option value="all">All Libraries</option>
              {libraries.filter(lib => lib !== 'all').map(lib => (
                <option key={lib} value={lib}>{lib}</option>
              ))}
            </Select>
          </SearchGroup>
          <SearchGroup>
            <SearchLabel>Color</SearchLabel>
            <ColorInput
              type="color"
              value={globalColor}
              onChange={(e) => setGlobalColor(e.target.value)}
            />
          </SearchGroup>
        </SearchRow>
        <SearchRow>
          <SearchGroup>
            <SearchLabel>Format</SearchLabel>
            <FormatToggle>
              <FormatButton
                active={format === 'PNG'}
                onClick={() => setFormat('PNG')}
              >
                PNG
              </FormatButton>
              <FormatButton
                active={format === 'JPG'}
                onClick={() => setFormat('JPG')}
              >
                JPG
              </FormatButton>
              <FormatButton
                active={format === 'WEBP'}
                onClick={() => setFormat('WEBP')}
              >
                WEBP
              </FormatButton>
              <FormatButton
                active={format === 'SVG'}
                onClick={() => setFormat('SVG')}
              >
                SVG
              </FormatButton>
            </FormatToggle>
          </SearchGroup>
        </SearchRow>
      </SearchContainer>

      <IconGrid>
        {paginatedIcons.map((icon, index) => (
          <IconCard key={icon.name}>
            <IconActions>
              <ActionButton onClick={() => downloadIcon(index, icon, format.toLowerCase())} title="Download">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                </svg>
              </ActionButton>
            </IconActions>
            <IconPreview 
              ref={el => iconRefs.current[index] = el}
              onClick={() => copyIcon(index)}
              copied={copiedIcon === index}
            >
              {React.createElement(icon.component, { 
                size: "2em",
                color: globalColor
              })}
            </IconPreview>
            <IconName>{icon.name}</IconName>
          </IconCard>
        ))}
      </IconGrid>
      {showCopyFeedback && (
        <CopyFeedback>
          Icon copied to clipboard!
        </CopyFeedback>
      )}
      <Pagination>
        <PageButton
          onClick={() => setCurrentPage(prev => prev - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </PageButton>
        <span>Page {currentPage} of {totalPages}</span>
        <PageButton
          onClick={() => setCurrentPage(prev => prev + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </PageButton>
      </Pagination>
    </div>
  );
};

export default IconToImage; 